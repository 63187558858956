import React from "react";
import {Fade, Slide} from "react-awesome-reveal";
import serimg1 from "../img/performance.png";
import serimg2 from "../img/ser1.png";


export default function About(){

    return (
        <main className="">
            <section className=" bg-aboutsec bg-no-repeat lg:bg-center bg-cover w-screen lg:h-[800px] relative">
                <div className=" w-full h-screen lg:h-full bg-black bg-opacity-40">
                    <div className=" flex lg:px-0 px-4 space-y-6 flex-col lg:max-w-screen-lg mx-auto h-full text-white justify-center items-center">
                        <h1 className=" text-main-color lg:text-6xl text-4xl font-header-font">About</h1>
                        <div className=" flex text-center lg:text-2xl text-lg space-y-4 flex-col">
                            <span className=" ">
                                Located in the heart of Silicon Valley, M2 Precision Machining is a premier contract manufacturer delivering 
                                top-quality parts across various industries. Our skilled and experienced operators are dedicated to producing exceptional designs and products for the defense, aerospace, 
                                automotive, medical, and semiconductor sectors. Equipped with cutting-edge machinery and tools, we ensure 
                                superior craftsmanship and innovation.
                            </span>

                            <span className="">
                                As a full-service shop, we not only manufacture precision parts and assemblies but also offer 
                                turnkey program integration and assembly services. Customer service is our highest priority, 
                                and you can rely on us for precision craftsmanship and timely delivery.
                            </span>

                            <span className=" lg:block hidden">
                                We have the expertise to work with a wide range of materials, including aluminum, brass, copper, 
                                titanium, stainless steel, and plastics. Our advanced capabilities include high-quality CNC milling, 
                                CNC turning, and 5-axis machining, ensuring we meet the diverse needs of our clients.
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg1} />
                            </div>
                            <div className="hidden group cursor-pointer lg:block lg:bg-bgser1 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                <div className=" group-hover:bg-opacity-20 w-full h-full bg-black bg-opacity-50"></div>
                            </div>
                            <Fade>
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-white/40">Our Performances</span>
                                    <span className=" text-lg text-white font-txt-font">
                                        Choose us for unparalleled accuracy and expertise. Experience cutting-edge machining services in a 
                                        state-of-the-art facility, ensuring each project meets the highest standards of quality and precision.
                                    </span>
                                    <div className=" font-txt-font text-white">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-xl lg:text-3xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className=" text-sky-200">
                                                        High-Volume Precision Machining                                                    
                                                    </span>
                                                </div>
                                                <span className=" text-white/50 ml-8 text-lg lg:text-xl font-thin">
                                                    Successfully fulfilled large-scale orders with high-precision requirements, consistently delivering on time and exceeding industry standards.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-xl lg:text-3xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className=" text-sky-200">
                                                        Custom Prototype Development
                                                    </span>
                                                </div>
                                                <span className=" text-white/50 ml-8 text-lg lg:text-xl font-thin">
                                                    Developed and produced custom prototypes with rapid turnaround times, helping clients bring innovative products to market quickly.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-xl lg:text-3xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                    <span className=" text-sky-200">
                                                        Complex Part Fabrication
                                                    </span>
                                                </div>
                                                <span className=" text-white/50 ml-8 text-lg lg:text-xl font-thin">
                                                    Executed complex part fabrication for critical industries such as aerospace and medical, ensuring superior quality and reliability.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade>
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-white/40">Targeting</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-gray-400">
                                            Serving the medical, solar, semiconductor, engineering, and high-tech industries.</span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Sectors
                                        </span>
                                    </div>
                                    <div className=" font-txt-font text-white">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className="">
                                                        Our Mission                                             
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    To deliver high-precision machining and exceptional quality using advanced CNC technology. We are dedicated to excellence in the medical, solar, semiconductor, engineering, and high-tech sectors.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className="">
                                                        Our Vision
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    To become the leading provider of innovative machining solutions, setting the benchmark for quality, precision, and customer satisfaction in the high-tech manufacturing industry.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg2} />
                            </div>
                            <div className="hidden group cursor-pointer lg:block lg:bg-bgser2 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                <div className=" group-hover:bg-opacity-20 w-full h-full bg-black bg-opacity-50"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>            
        </main>
    )
}