import {React} from "react";
import Iso from '../img/Cer/Iso.png';
import Itar from '../img/Cer/itar.png';
import Cage from '../img/Cer/cage.png';


function FloatBtn(){
    const Data = [Iso , Itar , Cage]
    return (
        <main>
            <div className= "flex flex-col lg:space-y-2 fixed bottom-4 lg:bottom-12 z-10 right-4">
                {Data.map((s,index) => (
                    <div key={index} className= "rounded-full flex justify-center items-center p-2 shadow-lg">
                        <button className="cursor-pointer" type="button">
                            <img alt="#1" src={s} className="lg:h-18 lg:w-18 w-16 h-16" />
                        </button>
                    </div>
                ))}
            </div>
        </main>
    );
};

export default FloatBtn;