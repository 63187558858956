import React from "react";
import spapic from "../img/Carousel/2.png"


export default function Contact(){
    return (
        <main>
            <section className=" bg-contsec bg-center bg-no-repeat bg-cover w-screen lg:h-[700px] relative">
                <div className=" w-full h-screen lg:h-full bg-black bg-opacity-80">
                    <div className=" flex lg:px-0 px-4 space-y-6 flex-col lg:max-w-screen-lg mx-auto h-full text-white justify-center items-center">
                        <h1 className=" text-main-color lg:text-6xl text-4xl font-header-font">Contact Us</h1>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className="mx-auto md:max-w-screen-xl cursor-pointer text-white">
                    <div className="grid grid-cols-1 text-justify md:gap-8 lg:grid-cols-3 ">
                        <div className="col-span-2 md:w-full w-screen p-4 space-y-4 h-[400px] md:h-full lg:space-y-8 transition ease-in-out duration-200 delay-100 hover:opacity-50">
                            <img className=" h-full w-full" src={spapic} alt="" />
                        </div>
                        <div className="p-4 space-y-4 lg:space-y-8 lg:text-justify lg:grid place-items-center justify-center text-center">
                            <div className="">
                                <div className="grid grid-cols-1 p-4 space-y-5">
                                    <div>
                                        <span className="text-3xl text-main-color tracking-wider">See us in person</span>
                                    </div>
                                    <div className="space-y-3">
                                        <p className="font-newFont">We love our customers and welcome them to visit Monday through Friday.</p>
                                        <ul className="space-y-2 font-semibold">
                                            <li>
                                                <a className="transition text-white t-underline t-underline-black border-b-yellow-500" target="_blank"  rel="noreferrer" href="https://www.google.com/maps/dir/36.1365504,-115.1696896/1641+Rogers+Ave,+San+Jose,+CA+95112/@36.594579,-121.1919643,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x808fcb8bac118117:0x941e3e16fab0b271!2m2!1d-121.905039!2d37.3701329?entry=ttu">
                                                    Address: 109 E Alma Ave, San Jose, CA 95112
                                                </a>
                                            </li>
                                            <li>
                                                <a className="transition text-white t-underline t-underline-black border-b-yellow-500" href="tel:4085644180">
                                                    Phone#: (408) 564-4180
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:mai.kieu@m2precisionmachining.com" className="transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Email#: mai.kieu@m2precisionmachining.com
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <div className="font-semibold text-lg text-sky-200">Business Hours:</div>
                                        <ul className="space-y-4 text-md mt-4">
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Mon: 7:00 am - 3:30 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Tues: 7:00 am - 3:30 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Wed: 7:00 am - 3:30 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Thu: 7:00 am - 3:30 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Fri: 7:00 am - 3:30 pm
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid justify-items-center">
                    <iframe className="w-full md:px-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6346.120335656995!2d-121.87540732425082!3d37.31740243837489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808e3330d19c344f%3A0xee0fda6399a51569!2s109%20E%20Alma%20Ave%2C%20San%20Jose%2C%20CA%2095112!5e0!3m2!1sen!2sus!4v1722628211294!5m2!1sen!2sus"  height="600" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </main>
    )
}