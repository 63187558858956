import React from "react";
import CarouselHome from "../Components/HomeCarousel";
import img1 from "../img/1.png";
import img2 from "../img/2.png";
import img3 from "../img/3.png";
import home3 from "../img/4.png";
import { Fade , Slide } from "react-awesome-reveal";
import serimg from "../img/serimg.png";
import cmm from "../img/cmm.png";
import turn from "../img/turn.png";
import SwiperMachine from "../Components/SwiperMachine";

export default function Home(){
    return(
        <main className="relative overflow-x-hidden">
            <section>
                <CarouselHome />
            </section>

            <section className=" bg-black" id="Home1">
                <div className=" pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                        <div className=" grid gap-8 lg:gap-12 place-items-center lg:grid-cols-4">
                            <Fade>
                                <div className=" flex flex-col lg:space-y-8">
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ease-in-out opacity-65 hover:opacity-100 transition-all cursor-pointer duration-1000 hover:scale-125" src={img1} />
                                    </div>
                                    <div className=" pb-8"></div>
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ml-4 ease-in-out opacity-65 hover:opacity-100 transition-all cursor-pointer duration-1000 hover:scale-125" src={img3} />
                                    </div>
                                </div>
                            </Fade>
                            <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                                <Slide duration={1000} direction="down">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-xl lg:text-2xl text-white/40">About us</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-2xl lg:text-4xl font-header-font text-gray-400">
                                            Embrace Precision, Innovate with M2 Precision Machining.
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className=" text-3xl lg:text-6xl text-main-color">
                                            Excellence
                                        </span>
                                    </div>
                                    <p className=" text-xl text-white font-txt-font">
                                        Established in the heart of Silicon Valley, San Jose, California, M2 is a leader in precision machining, 
                                        product assembly, and finishing services. With decades of expertise, we proudly serve industries including medical, 
                                        solar, semiconductor, engineering, aerospace, and defense. Trust M2 Precision Machining for
                                        unparalleled quality and precision in every project.
                                    </p>
                                    <div className="">
                                        <button onClick={() => {
                                            window.open("/About")
                                        }} className="hover:shadow-lg font-txt-font text-white hover:-translate-y-2 hover:bg-main2 hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-[2px] border-main2 text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                            <span className="">View More</span>
                                        </button>
                                    </div>
                                </Slide>
                            </div>
                            <Fade>
                                <div className=" flex flex-col lg:space-y-8">
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ease-in-out opacity-65 hover:opacity-100 cursor-pointer transition-all duration-1000 hover:scale-125" src={img2} />
                                    </div>
                                    <div className=" pb-8"></div>
                                    <div className="shadow-lg overflow-hidden">
                                        <img alt="da" className="ml-4 ease-in-out opacity-65 hover:opacity-100 cursor-pointer transition-all duration-1000 hover:scale-125" src={home3} />
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bgback lg:bg-center bg-no-repeat bg-cover lg:bg-fixed">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg} />
                            </div>
                            <div className="hidden group cursor-pointer lg:block lg:bg-bg-ser lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                <div className=" group-hover:bg-opacity-20 w-full h-full bg-black bg-opacity-40"></div>
                            </div>
                            <Fade delay={20}>
                                <div className="flex flex-col space-y-4 lg:space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-xl lg:text-3xl text-white/40">Discover Excellence</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-2xl lg:text-4xl font-header-font text-gray-400">
                                            with M2 Precision
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className=" text-3xl lg:text-6xl text-main-color">
                                            Machining
                                        </span>
                                    </div>
                                    <span className=" text-lg text-white font-txt-font">
                                        Unlock unparalleled accuracy and expertise with our advanced machining treatments. At M2 Precision Machining, 
                                        we deliver cutting-edge services in a state-of-the-art facility, ensuring every project meets 
                                        the highest standards of quality and precision.
                                    </span>
                                    <div className=" font-txt-font text-white">
                                        <ul className=" space-y-4 lg:space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className=" text-sky-200">
                                                        Expertise and Precision                                                    
                                                    </span>
                                                </div>
                                                <span className=" text-white/80 ml-8 text-lg font-thin">
                                                    Trust our skilled technicians to deliver top-notch machining with a commitment to perfection.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className=" text-sky-200">
                                                        Advanced Technology
                                                    </span>
                                                </div>
                                                <span className=" text-white/80 ml-8 text-lg font-thin">
                                                    Leverage our state-of-the-art equipment and innovative techniques to achieve superior quality.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                    <span className=" text-sky-200">
                                                        Personalized Solutions
                                                    </span>
                                                </div>
                                                <span className=" text-white/80 ml-8 text-lg font-thin">
                                                    Benefit from customized machining solutions tailored to meet the unique needs of your project."
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-bg22 bg-no-repeat lg:bg-center bg-cover">
                <div className=" bg-black bg-opacity-70">
                    <div className=" pt-6 pb-8 lg:pt-24 lg:pb-24 space-y-4">
                        <div className="lg:p-4 p-2 mx-auto max-w-screen-xl">
                            <div className=" grid gap-8 lg:gap-12 place-items-center lg:grid-cols-4">
                                <Fade>
                                    <div className=" flex flex-col lg:space-y-8">
                                        <div className="shadow-lg overflow-hidden">
                                            <img alt="da" className="ease-in-out transition-all cursor-pointer duration-1000 hover:scale-125" src={cmm} />
                                        </div>
                                    </div>
                                </Fade>
                                <div className=" flex lg:col-span-2 space-y-4 lg:space-y-8 flex-col capitalize text-center">
                                    <Slide direction="down">
                                        <span style={{fontFamily: 'Redressed'}} className=" text-xl lg:text-2xl text-white/40">What we Have?</span>
                                        <div className=" flex flex-col space-y-4">
                                            <span className=" text-2xl lg:text-4xl font-header-font text-gray-400">
                                                Precision CMM Inspection & Advanced CNC
                                            </span>
                                            <span style={{fontFamily: 'Calligraffitti'}} className=" text-5xl lg:text-6xl text-main-color">
                                                Turning
                                            </span>
                                        </div>
                                        <p className=" text-xl lg:text-2xl text-sky-200 font-txt-font">
                                            1) At M2 Precision Machining, we guarantee meticulous accuracy using state-of-the-art Coordinate Measuring Machines (CMM) for every component.
                                        </p>
                                        <p className="text-xl lg:text-2xl text-sky-200 font-txt-font">
                                            2) M2 Precision Machining specializes in CNC turning, producing high-precision, intricate parts with exceptional quality and efficiency.
                                        </p>
                                        <div className="">
                                            <button onClick={() => {
                                                window.open("/Services")
                                            }} className="hover:shadow-lg font-txt-font text-white hover:-translate-y-2 bg-main2 hover:text-white ease-in-out delay-100 duration-150 mt-5 md:text-xl border-[2px] border-main2 text-lg py-3 px-12 group cursor-pointer md:tracking-wide">
                                                <span className="">View More</span>
                                            </button>
                                        </div>
                                    </Slide>
                                </div>
                                <Fade>
                                    <div className=" flex flex-col lg:space-y-8">
                                        <div className="shadow-lg overflow-hidden">
                                            <img alt="da" className="ease-in-out cursor-pointer transition-all duration-1000 lg:hover:scale-125" src={turn} />
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-24 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className="flex flex-col text-center space-y-4 lg:space-y-8">
                            <span style={{fontFamily: 'Redressed'}} className=" text-xl lg:text-3xl text-white/40">Our Equipments</span>
                            <div className=" flex flex-col space-y-4">
                                <span className=" text-2xl lg:text-4xl font-header-font text-gray-400">
                                    Precision Engineered with Cutting-Edge
                                </span>
                                <span style={{fontFamily: 'Calligraffitti'}} className=" text-3xl lg:text-6xl text-main-color">
                                    Technology
                                </span>
                            </div>
                        </div>
                        <div>
                            <SwiperMachine />
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
};