import img1 from "../img/Machine/1.png";
import img2 from "../img/Machine/2.png";
import img3 from "../img/Machine/3.png";
import img4 from "../img/Machine/4.png";
import img5 from "../img/Machine/5.png";

const Data = [
    {
        id:1,
        name: 'HAAS',
        desc: '750ss',
        img: img1,
    },
    {
        id:2,
        name: 'Matsuura',
        img: img2,
        desc: 'MX-520'
    },
    {
        id:3,
        name: 'Matsuura',
        img: img3,
        desc: 'MX-850'
    },
    {
        id:4,
        name: 'HASS',
        img: img4,
        desc: 'VF 6ss'
    },
    {
        id:5,
        name: 'ZEISS',
        img: img5,
        desc: 'CMM'
    },
];

export default Data;