import img2 from "../img/ser/1.png";
import serimg from "../img/ser/2.png";
import ser3 from "../img/ser/3.png";
import ser5 from "../img/ser/4.png";

const ServicesData = [
    {
        id: 1,
        name: 'Precision CNC Engineering Services',
        desc: 'Discover expert CNC machining with our Precision CNC Engineering Services. We specialize in fabricating components from materials such as Aluminum, Stainless Steel, Titanium, Copper, Brass, and Plastics, delivering unmatched accuracy and quality.',
        img: img2,
    },
    {
        id: 2,
        name: 'Rapid Prototyping & Quick Turnaround Manufacturing',
        desc: 'Our Rapid Prototyping Services ensure high-quality parts within tight deadlines, with delivery in as little as two days. Trust our efficient processes for quick, reliable, and top-notch prototypes that meet your urgent needs.',
        img: serimg,
    },
    {
        id: 3,
        name: 'Cost-Efficient Large-Scale Manufacturing Solutions',
        desc: 'Optimize your production with our Large-Scale Manufacturing Services, featuring APQP and PPAP implementations. We ensure product consistency and quality, providing cost-efficient solutions for your long-term manufacturing needs.',
        img: ser3,
    },
    {
        id: 4,
        name: 'Comprehensive Vendor Network & Manufacturing Solutions',
        desc: 'Leverage our Comprehensive Vendor Network Solutions for an integrated production experience. We connect you with a vast network of vetted vendors, offering a seamless, end-to-end manufacturing solution tailored to your requirements.',
        img: ser5
    }
];

export default ServicesData;
