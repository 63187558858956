import {Swiper , SwiperSlide} from 'swiper/react';
import {Autoplay , Navigation} from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import Data from './Data';
import 'swiper/css';
import 'swiper/css/navigation';
import React , {useRef} from 'react';

export default function SwiperMachine() { 
    
    const swiperRef = useRef(null);

    return (
        <div className=' relative'>
            <Swiper
            onSwiper={(swiper) => swiperRef.current = swiper}
                loop = {true}
                breakpoints={{
                    340: {
                        slidesPerView:1,
                    },
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    }
                }}
                autoplay = {{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                // navigation = {true}
                modules={[Autoplay]}
            >
                {Data.map((s) => {
                    return (
                        <SwiperSlide className='' key={s.id}>
                            <div className="lg:p-4 border-2 border-sky-200">
                                <div className=" cursor-pointer flex sticky lg:hidden">
                                    <img alt="#" className=" rounded-lg h-full w-full" src={s.img} />
                                    <div className="absolute bg-black bg-opacity-10 p-1 inset-0 z-10 flex flex-col justify-end opacity-100">
                                        <div className=' bg-black/30 p-2'>
                                            <div className="ml-3 text-2xl font-bold text-green-300">
                                                <h1>{s.name}</h1>
                                            </div>
                                            <div className=" flex ml-4 text-lg text-sky-300 items-center space-x-1">
                                                <span className="">{s.desc}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sticky cursor-pointer lg:block hidden">
                                    <img alt="#" className="rounded-lg h-full w-full " src={s.img} />
                                    <div className=" absolute p-8 inset-0 z-10 bg-black flex flex-col justify-end opacity-0 hover:opacity-100 group ease-linear duration-500 transition-all bg-opacity-50">
                                        <div className="ml-3 text-3xl">
                                            <h1 className=' group-hover:text-main-color'>{s.name}</h1>
                                        </div>
                                        <div className="ml-3 text-2xl">
                                            <h1 className=' group-hover:text-sky-200'>{s.desc}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )  
};