import React from "react";
import { RxDoubleArrowDown } from "react-icons/rx";
import ServicesData from "../Components/ServicesData";
import {Gallery as Glery , Item , } from "react-photoswipe-gallery";
import 'photoswipe/dist/photoswipe.css';
import {Fade, Slide} from "react-awesome-reveal";

function importImg(r){
    return r.keys().map(r);
}


export default function Services(){

    const fnames = importImg(require.context('../img/Products/' , false , /\.(png|jpg|jpeg)$/));

    const options = {
        zoom: false,
        tapAction:'close',
        doubleTapAction: false,
    };

    function scrollSec(){
        const home1 = document.getElementById('Ser1');
        home1?.scrollIntoView({
            behavior:"smooth"
        });
    };


    return(
        <main>
            <section className=" bg-sersec bg-center bg-no-repeat bg-cover w-screen lg:h-[700px] relative">
                <div className=" w-full h-screen lg:h-full bg-black bg-opacity-80">
                    <div className=" flex lg:px-0 px-4 space-y-6 flex-col lg:max-w-screen-lg mx-auto h-full text-white justify-center items-center">
                        <h1 className=" text-main-color lg:text-6xl text-4xl font-header-font">Services</h1>
                        <div className=" flex lg:text-2xl text-lg space-y-4 flex-col">
                            <span className="text-center text-lg">
                                We are a full-service manufacturing shop, offering comprehensive solutions for prototyping, production, 
                                and assembly packages. Our versatile range of equipment enables us to handle everything from high-volume 
                                turning jobs to precision 5-axis machining of complex parts, all with quick turnaround times. 
                                We take pride in tackling any challenge and continuously expanding our services, which include:
                            </span>
                        </div>
                        <div className="p-4 flex justify-center items-center">
                            <button onClick={() => scrollSec()} className="group ">
                                <RxDoubleArrowDown className="ease-in-out delay-100 duration-150 group-hover:-translate-y-2 group-hover:text-main2 text-5xl" />
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Ser1" className="bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-4 lg:grid-cols-4">
                            {ServicesData.map((s) => (
                                <div key={s.id} className="cursor-pointer shadow-lg h-full rounded-lg mb-2 ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                    <div className="overflow-hidden">
                                        <img src={s.img} alt="#" className="w-full" />
                                    </div>
                                    <div className="px-4 space-y-2 mb-4 mt-4">
                                        <h1 className="text-xl font-header-font text-sky-200 text-center capitalize py-1">{s.name}</h1>
                                        <div className="text-lg flex space-y-2 flex-col font-txt-font text-white tracking-wide text-center">
                                            <div className="text-justify ">
                                                {s.desc}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            

            <section className=" bg-black">
            {/* <Slide delay={500} duration={500} triggerOnce direction="left"> */}
                <div className="pt-6 pb-8 ">
                    <div class="cursor-pointer md:px-24 px-6 overflow-x-hidden">
                        <div class="grid md:grid-cols-4 grid-cols-2 justify-items-center gap-2 cursor-pointer">
                            <Glery options={options}>
                                {fnames.map((fname , index) => (
                                    <>
                                        <div key={index} class="p-1 shadow-lg md:p-2">
                                            <Item width="900" height="900"key={index} original={fname}  alt= "#">
                                                {({ref , open}) => (
                                                    <img className=" rounded-lg lg:w-[500px] w-[150px] h-[150px] lg:h-[300px] hover:opacity-70 transition-opacity ease-in" alt="#" ref={ref} src={fname}  onClick={open} />
                                                )}
                                            </Item>
                                        </div>
                                    </>
                                ))}
                            </Glery>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}